<template class="text_google">
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>

        <v-btn
          @click.stop="exportToExcel('exportContent', 'เสนอกลั่นกรอง')"
          class="mr-1"
          color="green"
        >
          <v-icon>mdi-file-excel</v-icon>&nbsp; Export to excel
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div
      id="exportContent"
      style="mso-page-orientation: landscape; overflow-x: scroll;"
    >
      <div class="page">
        <div align="center">
          <div class="head">
            บัญชีรายละเอียดการพิจารณาย้ายข้าราชการครูและบุคลากรทางการศึกษาประจำปี
            ครั้งที่ {{ order_appoints.time_s }}/{{ order_appoints.year_s }}
          </div>
          <div class="head">
            แนบท้ายวาระการประชุมคณะกรรมการกลั่นกรองฯ
            สำนักงานคณะกรรมการการอาชีวศึกษา ครั้งที่
            {{ order_appoints.meeting_no }} เมื่อวันที่
            {{
              order_appoints.meeting_date
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </div>
          <div class="head">
            วาระ (ลับ) การย้ายกรณีปกติข้าราชการครูและบุคลากรทางการศึกษา
            ตำแหน่งครู จำนวน {{ order_appoints.count_personnel_demand }} ราย
          </div>
        </div>
        <table class="table" border="1" width="100%" align="center">
          <thead>
            <tr>
              <td width="5%" class="bold16" rowspan="2" align="center">ที่</td>
              <td width="25%" class="bold16" colspan="2" align="center">
                อัตราว่าง
              </td>
              <td width="70%" class="bold16" colspan="10" align="center">
                พิจารณาคัดเลือก
              </td>
            </tr>
            <tr>
              <td width="15%" class="bold16 pa-1" align="center">สังกัดใหม่</td>
              <td width="5%" class="bold16 pa-1" align="center">สรุป</td>
              <td width="5%" class="bold16 pa-1" align="center">ขาดเกิน</td>
              <td width="5%" class="bold16 pa-1" align="center">
                เลขที่ตำแหน่งว่าง
              </td>
              <td width="15%" class="bold16 pa-1" align="center">
                รหัสบัตรประชาชน
              </td>
              <td width="15%" class="bold16 pa-1" align="center">ชื่อ-สกุล</td>
              <td width="15%" class="bold16 pa-1" align="center">สังกัดเดิม</td>
              <td width="5%" class="bold16 pa-1" align="center">สรุป</td>
              <td width="5%" class="bold16 pa-1" align="center">ขาดเกิน</td>
              <td width="5%" class="bold16 pa-1" align="center">เลขที่</td>
              <td width="15%" class="bold16 pa-1" align="center">สาขา</td>
              <td width="5%" class="bold16 pa-1" align="center">
                เปิดรับ/ไม่เปิดรับ
              </td>
              <td width="20%" class="bold16 pa-1" align="center">
                เหตุผลในการย้าย
              </td>
              <td width="20%" class="bold16 pa-1" align="center">
                อายุงาน
              </td>
              <td width="20%" class="bold16 pa-1" align="center">
                ช่วยราชการ
              </td>

              <!--  <td width="40%" class="bold16 pa-1" align="center">
                ผู้เสนอย้ายสาขาวิชาเดียวกัน
              </td> -->

              <td height="100" class="regular16 pa-1" valign="bottom">
                <span class="textAlignVer">ลำดับที่ขอย้าย</span>
              </td>
              <td height="130" class="regular16 pa-1" valign="bottom">
                <span class="textAlignVer">อายุราชการในสถานศึกษาปัจจุบัน</span>
              </td>
              <td height="100" class="regular16 pa-1" valign="bottom">
                <span class="textAlignVer">อายุราชการตั้งแต่บรรจุ</span>
              </td>
              <td height="100" class="regular16 pa-1" valign="bottom">
                <span class="textAlignVer">ช่วยปฏิบัติราชการ</span>
              </td>

              <td width="10%" class="bold16 pa-1" align="center">
                ความคิดเห็น ผอ
              </td>
              <td width="10%" class="bold16 pa-1" align="center">
                ความคิดเห็น ผอ สถบ.
              </td>

              <td width="10%" class="bold16 pa-1" align="center">ปลายทาง</td>
              <td width="10%" class="bold16 pa-1" align="center">
                คะแนน สอจ. (60)
              </td>
              <td width="10%" class="bold16 pa-1" align="center">
                คะแนน กก. (40)
              </td>
              <td width="10%" class="bold16 pa-1" align="center">รวม (100)</td>
              <td width="5%" class="bold16 pa-1" align="center">สถานะ</td>
              <td width="5%" class="bold16 pa-1" align="center">
                รูปแบบการย้าย
              </td>
            </tr>
          </thead>

          <tbody
            v-for="(item, index) in conditons_transfer_successs"
            :key="item.id_cts"
          >
            <tr>
              <td class="regular16" align="center">{{ index + 1 }}</td>
              <td class="regular16 pa-1" align="center">
                {{ item.college_name_new }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.summary_s }}
              </td>

              <td class="regular16 pa-1" align="center">
                {{ item.total_s }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.ctsid_position }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.ctsid_card }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.title_s }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.college_name_old }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.summary_sOld }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.total_sOld }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.ctsid_postion_old }}
              </td>

              <td class="regular16 pa-1" align="left">
                {{ item.ctsid_branch }} : {{ item.branch_name }}
              </td>
              <td class="regular16 pa-1" align="center">
                <span v-if="item.status_select === 'sw_normal'">
                  ย้ายสับเปลี่ยน
                </span>
                <span v-else>
                  เปิดรับ
                </span>
              </td>

              <td class="regular16 pa-1" align="left">
                {{ item.reason }}
              </td>
              <td class="regular16 pa-1" align="left">
                {{ item.age_app_time }}
              </td>
              <td class="regular16 pa-1" align="center">
                <v-chip
                  color="green"
                  dark
                  v-if="item.service_status === 'not_service'"
                >
                  ปกติ
                </v-chip>
                <v-chip v-else color="warning" dark>
                  ช่วยราชการ
                </v-chip>
              </td>
              <!--   <td class="regular16 pa-1" align="left">
                {{ item.personel_Need }}
              </td> -->

              <td class="regular16 pa-1" align="center">
                {{ item.tlsequence_n }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.age_app_time }}
              </td>
              <td class="regular16 pa-1" align="center">{{ item.age_time }}</td>
              <td class="regular16 pa-1" align="center">
                <span v-if="item.service_status === 'gov_service'"
                  ><v-icon>mdi-check</v-icon></span
                >
                <span v-else
                  ><v-icon>mdi-checkbox-blank-circle-outline</v-icon></span
                >
              </td>

              <td class="regular16 pa-1" align="left">
                <v-chip
                  v-if="item.comment_dr_c === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="item.comment_dr_c === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>
              <td class="regular16 pa-1" align="left">
                <v-chip
                  v-if="item.tp_comment_dr_stb === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="item.tp_comment_dr_stb === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>

              <td class="regular16 pa-1" align="center">
                <v-chip
                  v-if="item.Mcommittee === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="item.Mcommittee === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>

              <td class="regular16 pa-1" align="center">
                {{ item.tpvecprovince_score }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.score_1420 }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{
                  parseFloat(
                    parseFloat(item.tpvecprovince_score || 0) +
                    parseFloat(item.score_1420 || 0)
                  ).toFixed(2)
                }}
              </td>
              <td class="regular16 pa-1" align="center">
                <v-chip
                  v-if="item.status_document === 'complete'"
                  color="green"
                  dark
                  >เอกสารถูกต้องสมบูรณ์</v-chip
                >
                <v-chip
                  v-else-if="item.status_document === 'incomplete'"
                  color="warning"
                  dark
                  >เอกสารไม่สมบูรณ์</v-chip
                >
                <v-chip
                  v-else-if="item.status_document === 'do_not'"
                  color="red"
                  dark
                  >ไม่จัดส่งเอกสาร</v-chip
                >
              </td>
              <td class="regular16 pa-1">
                <span v-if="item.ctsstatus_select === 'demand'"> ปกติ</span>
                <span v-if="item.ctsstatus_select === 'agree'"> ปกติ</span>
                <span v-if="item.ctsstatus_select === 'transfer'"> ตัดโอน</span>
                <span v-if="item.ctsstatus_select === 'sw_normal'">
                  สับเปลี่ยน</span
                >
                <span v-if="item.ctsstatus_select === 'sw_agree'">
                  (แลกเปลี่ยน)</span
                >
              </td>
            </tr>
            <tr
              v-for="itemchild in item.transference_locations"
              :key="itemchild.id"
              style="background-color: antiquewhite;"
            >
              <td class="text-center" colspan="5"></td>

              <td class="text-center">
                {{ itemchild.tlid_card }}
              </td>
              <td class="text-center">
                {{ itemchild.tranPersonel }}
              </td>
              <td class="text-center">{{ itemchild.college_name }}</td>
              <td class="text-center">{{ itemchild.summary_sOld }}</td>
              <td class="text-center">{{ itemchild.total_sOld }}</td>
              <td class="text-center">{{ itemchild.id_position }}</td>
              <td class="regular16 pa-1" align="left">
                {{ itemchild.tlid_branch }} : {{ itemchild.branch_name }}
              </td>
              <td></td>
              <td class="regular16 pa-1" align="left">
                {{ itemchild.reason }}
              </td>
              <td class="regular16 pa-1" align="left">
                {{ itemchild.age_app_time }}
              </td>
              <td class="regular16 pa-1" align="center">
                <v-chip
                  color="green"
                  dark
                  v-if="itemchild.service_status === 'not_service'"
                >
                  ปกติ
                </v-chip>
                <v-chip v-else color="warning" dark>
                  ช่วยราชการ
                </v-chip>
              </td>
              <td class="regular16 pa-1" align="center">
                {{ itemchild.tlsequence_n }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ itemchild.age_app_time }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ itemchild.age_time }}
              </td>
              <td class="regular16 pa-1" align="center">
                <span v-if="itemchild.service_status === 'service'"
                  ><v-icon>mdi-check</v-icon></span
                >
              </td>

              <td class="regular16 pa-1" align="left">
                <v-chip
                  v-if="itemchild.comment_dr_c === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="itemchild.comment_dr_c === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>
              <td class="regular16 pa-1" align="left">
                <v-chip
                  v-if="itemchild.tp_comment_dr_stb === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="itemchild.tp_comment_dr_stb === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>

              <td class="regular16 pa-1" align="center">
                <v-chip
                  v-if="itemchild.Mcommittee === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="itemchild.Mcommittee === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>

              <td class="regular16 pa-1" align="center">
                {{ itemchild.tpvecprovince_score }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ itemchild.score_1420 }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{
                  parseFloat(
                    parseFloat(itemchild.tpvecprovince_score || 0) +
                    parseFloat(itemchild.score_1420 || 0)
                  ).toFixed(2)
                }}
              </td>
              <td class="regular16 pa-1" align="center">
                <v-chip
                  v-if="itemchild.status_document === 'complete'"
                  color="green"
                  dark
                  >เอกสารถูกต้องสมบูรณ์</v-chip
                >
                <v-chip
                  v-else-if="itemchild.status_document === 'incomplete'"
                  color="warning"
                  dark
                  >เอกสารไม่สมบูรณ์</v-chip
                >
                <v-chip
                  v-else-if="itemchild.status_document === 'do_not'"
                  color="red"
                  dark
                  >ไม่จัดส่งเอกสาร</v-chip
                >
              </td>
            </tr>
          </tbody>
        </table>

        <p style="page-break-before: always;">&nbsp;</p>

        <div align="center">
          <div class="head">
            บัญชีรายละเอียดการพิจารณาย้ายข้าราชการครูและบุคลากรทางการศึกษาประจำปี
            ครั้งที่ {{ order_appoints.time_s }}/{{ order_appoints.year_s }}
          </div>
          <div class="head">
            แนบท้ายวาระการประชุมคณะกรรมการกลั่นกรองฯ
            สำนักงานคณะกรรมการการอาชีวศึกษา ครั้งที่
            {{ order_appoints.meeting_no }} เมื่อวันที่
            {{
              order_appoints.meeting_date
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </div>
          <div class="head">
            วาระ (ลับ) การย้ายกรณีปกติข้าราชการครูและบุคลากรทางการศึกษา (ตัดโอน)
            ตำแหน่งครู จำนวน {{ order_appoints.count_personnel_transfer }} ราย
          </div>
        </div>

        <table class="table" border="1" width="100%" align="center">
          <thead>
            <tr>
              <td width="5%" class="bold16" rowspan="2" align="center">ที่</td>
              <td width="25%" class="bold16" colspan="2" align="center">
                อัตราว่าง
              </td>
              <td width="70%" class="bold16" colspan="10" align="center">
                พิจารณาคัดเลือก
              </td>
            </tr>
            <tr>
              <td width="15%" class="bold16 pa-1" align="center">สังกัดใหม่</td>
              <td width="5%" class="bold16 pa-1" align="center">สรุป</td>
              <td width="5%" class="bold16 pa-1" align="center">ขาดเกิน</td>
              <td width="5%" class="bold16 pa-1" align="center">
                เลขที่ตำแหน่งว่าง
              </td>
              <td width="15%" class="bold16 pa-1" align="center">
                รหัสบัตรประชาชน
              </td>
              <td width="15%" class="bold16 pa-1" align="center">ชื่อ-สกุล</td>
              <td width="15%" class="bold16 pa-1" align="center">สังกัดเดิม</td>
              <td width="5%" class="bold16 pa-1" align="center">สรุป</td>
              <td width="5%" class="bold16 pa-1" align="center">ขาดเกิน</td>
              <td width="5%" class="bold16 pa-1" align="center">เลขที่</td>
              <td width="15%" class="bold16 pa-1" align="center">สาขา</td>
              <td width="5%" class="bold16 pa-1" align="center">
                เปิดรับ/ไม่เปิดรับ
              </td>
              <td width="20%" class="bold16 pa-1" align="center">
                เหตุผลในการย้าย
              </td>
              <td width="20%" class="bold16 pa-1" align="center">
                อายุงาน
              </td>
              <td width="20%" class="bold16 pa-1" align="center">
                ช่วยราชการ
              </td>

              <!--  <td width="40%" class="bold16 pa-1" align="center">
                ผู้เสนอย้ายสาขาวิชาเดียวกัน
              </td> -->

              <td height="100" class="regular16 pa-1" valign="bottom">
                <span class="textAlignVer">ลำดับที่ขอย้าย</span>
              </td>
              <td height="130" class="regular16 pa-1" valign="bottom">
                <span class="textAlignVer">อายุราชการในสถานศึกษาปัจจุบัน</span>
              </td>
              <td height="100" class="regular16 pa-1" valign="bottom">
                <span class="textAlignVer">อายุราชการตั้งแต่บรรจุ</span>
              </td>
              <td height="100" class="regular16 pa-1" valign="bottom">
                <span class="textAlignVer">ช่วยปฏิบัติราชการ</span>
              </td>

              <td width="10%" class="bold16 pa-1" align="center">
                ความคิดเห็น ผอ
              </td>
              <td width="10%" class="bold16 pa-1" align="center">
                ความคิดเห็น ผอ สถบ.
              </td>

              <td width="10%" class="bold16 pa-1" align="center">ปลายทาง</td>
              <td width="10%" class="bold16 pa-1" align="center">
                คะแนน สอจ. (80)
              </td>
              <td width="10%" class="bold16 pa-1" align="center">
                คะแนน กก. (20)
              </td>
              <td width="10%" class="bold16 pa-1" align="center">รวม (100)</td>
              <td width="5%" class="bold16 pa-1" align="center">สถานะ</td>
              <td width="5%" class="bold16 pa-1" align="center">
                รูปแบบการย้าย
              </td>
            </tr>
          </thead>

          <tbody
            v-for="(item, index) in conditons_transfer_successsCut"
            :key="item.id_cts"
          >
            <tr>
              <td class="regular16" align="center">{{ index + 1 }}</td>
              <td class="regular16 pa-1" align="center">
                {{ item.college_name_new }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.summary_s }}
              </td>

              <td class="regular16 pa-1" align="center">
                {{ item.total_s }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.ctsid_position }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.ctsid_card }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.title_s }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.college_name_old }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.summary_sOld }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.total_sOld }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.ctsid_postion_old }}
              </td>

              <td class="regular16 pa-1" align="left">
                {{ item.ctsid_branch }} : {{ item.branch_name }}
              </td>
              <td class="regular16 pa-1" align="center">
                <span v-if="item.status_select === 'sw_normal'">
                  ย้ายสับเปลี่ยน
                </span>
                <span v-else>
                  เปิดรับ
                </span>
              </td>

              <td class="regular16 pa-1" align="left">
                {{ item.reason }}
              </td>
              <td class="regular16 pa-1" align="left">
                {{ item.age_app_time }}
              </td>
              <td class="regular16 pa-1" align="center">
                <v-chip
                  color="green"
                  dark
                  v-if="item.service_statuss === 'not_service'"
                >
                  ปกติ
                </v-chip>
                <v-chip v-else color="warning" dark>
                  ช่วยราชการ
                </v-chip>
              </td>
              <!--   <td class="regular16 pa-1" align="left">
                {{ item.personel_Need }}
              </td> -->

              <td class="regular16 pa-1" align="center">
                {{ item.tlsequence_n }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.age_app_time }}
              </td>
              <td class="regular16 pa-1" align="center">{{ item.age_time }}</td>
              <td class="regular16 pa-1" align="center">
                <span v-if="item.service_status === 'gov_service'"
                  ><v-icon>mdi-check</v-icon></span
                >
                <span v-else
                  ><v-icon>mdi-checkbox-blank-circle-outline</v-icon></span
                >
              </td>

              <td class="regular16 pa-1" align="left">
                <v-chip
                  v-if="item.comment_dr_c === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="item.comment_dr_c === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>
              <td class="regular16 pa-1" align="left">
                <v-chip
                  v-if="item.tp_comment_dr_stb === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="item.tp_comment_dr_stb === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>

              <td class="regular16 pa-1" align="center">
                <v-chip
                  v-if="item.Mcommittee === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="item.Mcommittee === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>

              <td class="regular16 pa-1" align="center">
                {{ item.tpvecprovince_score }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ item.score_1420 }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{
                  parseFloat(
                    parseFloat(item.tpvecprovince_score || 0) +
                    parseFloat(item.score_1420 || 0)
                  ).toFixed(2)
                }}
              </td>
              <td class="regular16 pa-1">
                <span v-if="item.ctsstatus_select === 'demand'"> ปกติ</span>
                <span v-if="item.ctsstatus_select === 'agree'"> ปกติ</span>
                <span v-if="item.ctsstatus_select === 'transfer'"> ตัดโอน</span>
                <span v-if="item.ctsstatus_select === 'sw_normal'">
                  สับเปลี่ยน</span
                >
                <span v-if="item.ctsstatus_select === 'sw_agree'">
                  (แลกเปลี่ยน)</span
                >
              </td>
            </tr>
            <tr
              v-for="(itemchild, i) in item.transference_locations"
              :key="itemchild.id"
              style="background-color: antiquewhite;"
            >
              <td class="text-center">
                {{ i++ }}
              </td>

              <td class="text-center"></td>
              <td class="text-center"></td>
              <td class="text-center"></td>
              <td class="text-center"></td>
              <td class="text-center">
                {{ itemchild.tlid_card }}
              </td>
              <td class="text-center">
                {{ itemchild.tranPersonel }}
              </td>
              <td class="text-center">{{ itemchild.college_name }}</td>
              <td class="text-center">{{ itemchild.summary_sOld }}</td>
              <td class="text-center">{{ itemchild.total_sOld }}</td>
              <td class="text-center">{{ itemchild.id_position }}</td>
              <td class="regular16 pa-1" align="left">
                {{ itemchild.tlid_branch }} : {{ itemchild.branch_name }}
              </td>
              <td></td>
              <td class="regular16 pa-1" align="left">
                {{ itemchild.reason }}
              </td>
              <td class="regular16 pa-1" align="left">
                {{ itemchild.age_app_time }}
              </td>
              <td class="regular16 pa-1" align="center">
                <v-chip
                  color="green"
                  dark
                  v-if="itemchild.service_status === 'not_service'"
                >
                  ปกติ
                </v-chip>
                <v-chip v-else color="warning" dark>
                  ช่วยราชการ
                </v-chip>
              </td>
              <td class="regular16 pa-1" align="center">
                {{ itemchild.tlsequence_n }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ itemchild.age_app_time }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ itemchild.age_time }}
              </td>
              <td class="regular16 pa-1" align="center">
                <span v-if="itemchild.service_status === 'service'"
                  ><v-icon>mdi-check</v-icon></span
                >
              </td>

              <td class="regular16 pa-1" align="left">
                <v-chip
                  v-if="itemchild.comment_dr_c === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="itemchild.comment_dr_c === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>
              <td class="regular16 pa-1" align="left">
                <v-chip
                  v-if="itemchild.tp_comment_dr_stb === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="itemchild.tp_comment_dr_stb === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>

              <td class="regular16 pa-1" align="center">
                <v-chip
                  v-if="itemchild.Mcommittee === 'approp'"
                  color="success"
                  dark
                >
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="itemchild.Mcommittee === 'inapprop'"
                  color="#B71C1C"
                  dark
                >
                  ไม่เห็นควร
                </v-chip>
                <v-chip v-else color="warning" dark>ไม่ได้ดำเนินการ</v-chip>
              </td>

              <td class="regular16 pa-1" align="center">
                {{ itemchild.tpvecprovince_score }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{ itemchild.score_1420 }}
              </td>
              <td class="regular16 pa-1" align="center">
                {{
                  parseFloat(
                    parseFloat(itemchild.tpvecprovince_score || 0) +
                    parseFloat(itemchild.score_1420 || 0)
                  ).toFixed(2)
                }}
              </td>
              <td class="regular16 pa-1" align="center">
                <v-chip
                  v-if="itemchild.status_document === 'complete'"
                  color="green"
                  dark
                  >เอกสารถูกต้องสมบูรณ์</v-chip
                >
                <v-chip
                  v-else-if="itemchild.status_document === 'incomplete'"
                  color="warning"
                  dark
                  >เอกสารไม่สมบูรณ์</v-chip
                >
                <v-chip
                  v-else-if="itemchild.status_document === 'do_not'"
                  color="red"
                  dark
                  >ไม่จัดส่งเอกสาร</v-chip
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    order_appoints: [],
    order_appointsNum: [],
    conditons_transfer_successs: [],
    conditons_transfer_successsCut: [],
    valid: true
  }),

  async mounted() {
    await this.sweetAlertLoading();
    await this.order_appointQueryAll();
    await this.order_appoint_num();
    await this.conditons_transfer_successQueryAll();
    await this.conditons_transfer_successCutQueryAll();
    Swal.close();
  },

  methods: {
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async order_appointQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("order_appoint.php", {
          ApiKey: this.ApiKey,
          id_oa: this.id_url
        })
        .finally(() => (this.loading = false));
      this.order_appoints = result.data;

    },

    async order_appoint_num() {
      let result = await this.$http.post("order_appoint.php", {
        ApiKey: this.ApiKey,
        id_oa: this.id_url,
        time_s: this.order_appoints.time_s,
        year_s: this.order_appoints.year_s,
        name_position: this.position_url
      });
      this.order_appointsNum = result.data;
    },

    async conditons_transfer_successQueryAll() {
      let result = await this.$http.post(
        "conditons_transfer_success_filter_3.php",
        {
          ApiKey: this.ApiKey,
          time_s: this.order_appoints.time_s,
          year_s: this.order_appoints.year_s,
          name_position: this.position_url,
          ctsstatus_selectfrist: "ok"
        }
      );
      this.conditons_transfer_successs = result.data;

  
    },

    async conditons_transfer_successCutQueryAll() {
      let result = await this.$http.post(
        "conditons_transfer_success_filter_3.php",
        {
          ApiKey: this.ApiKey,
          time_s: this.order_appoints.time_s,
          year_s: this.order_appoints.year_s,
          name_position: this.position_url,
          ctsstatus_selectsecord: "transfer"
        }
      );
      this.conditons_transfer_successsCut = result.data;
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    },
    exportToExcel(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msexcel"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".xls" : "document.xls";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  props: {
    source: String
  },

  computed: {
    url_result() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },

    position_url() {
      let result = this.url_result.slice(6);
      if (result == "teach") {
        result = "ครู";
      } else {
        result = "บริหาร";
      }
      return result;
    },
    id_url() {
      let result = this.url_result;
      return result[0];
    }
  }
};
</script>
<style>
@import url(
  https://fonts.googleapis.com/css?family=Prompt:400,
  300|Roboto:400,
  300&subset=latin,
  thai
);

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.textAlignVer {
  display: block;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  width: 20px;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 60cm;
  min-height: 21cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 0.5cm;
  padding-right: 0.5cm;
  padding-bottom: 0.5cm;
  padding-left: 0.5cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
      height: 247mm;
    }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
        font-size: 18px;
        font-weight: bold;
      }
      .regular12 {
        font-size: 12px;
      }
      .blod12 {
        font-size: 12px;
        font-weight: bold;
      }
      .blackRegula8 {
        font-size: 8px;
      } */
  .noprint {
    display: none;
  }
}
</style>
